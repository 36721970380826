import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Text from '../components/Blocks/Text';


export const TextWidget = {
  // Internal id of the component
  id: "Custom-Text",
  // Visible label
  label: "Custom-Text",
  fields: [
    {
      name: 'text', label: 'Text', widget: 'string'
    },
    {
      name: 'size', label: 'Size', widget: 'select', options: ['', 'h1', 'h2', 'h3']
    },
    {
      name: 'color', label: 'Color', widget: 'select', options: ['', 'pink', 'orange', 'yellow', 'teal']
    },
    {
      name: 'align', label: 'Align', widget: 'select', options: ['', 'left', 'right', 'center']
    },
  ],
  pattern: /^{{{Text text="(.*)" size="(.*)" color="(.*)" align="(.*)"}}}$/,
  fromBlock: function(match) {
    return {
      text: match[1],
      size: match[2],
      color: match[3],
      align: match[4],
    };
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return `{{{Text text="${obj.text}" size="${obj.size}" color="${obj.color}" align="${obj.align}"}}}`;
  },
  toView: function(obj) {
    const { size, color, align } = obj;
    return (
      <Text
        text={obj.text}
        className={cx(_.values({
          size,color, align
        }))}
      />
    );
  },
  toPreview: function(obj) {
    const { size, color, align } = obj;
    return (
      <Text
        text={obj.text}
        className={cx(_.values({
          size,color, align
        }))}
      />
    );
  }  
}

export default TextWidget;
