import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import TrackVisibility from 'react-on-screen';
import Slider from 'react-slick';
import _ from 'lodash';
import cx from 'classnames';
import SliderContainer from '../../styles/SliderContainer';
import Card from './Card';
import Context from './Context';

import 'slick-carousel/slick/slick.css';

const ColumnStyle = styled(Grid.Column)`
  outline: none !important;
`;

const Question = styled.p`
  font-size: ${props => props.theme.sizeMobileText};
  margin-left: 24px;
`;

const rowHeight = 533;
const RowPlaceholder = styled.div`
  height: ${rowHeight}px;
`;

const RowWrapper = ({ isVisible, children }) => {
  if (!isVisible) return <RowPlaceholder />;
  return children;
};

const ArticleSlider = props => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    card: {
      height: 348,
    },
    resp: {
      mobile: {
        card: {
          height: 175,
        }
      }
    }
  };
  const { title, articles } = props;
  const CardComponent = _.get(props, 'CardComponent', Card);
  return (
    <Context.Provider value={settings}>
      <TrackVisibility once offset={rowHeight * 1.1}>
        <RowWrapper>
          <div className={cx(props.className)}>
            <Question>{title}</Question>
            <SliderContainer settings={settings}>
              <Slider {...settings}>
                {articles.map((article, i) => (
                  <ColumnStyle key={`${i * 2}`}>
                    <CardComponent {...article} settings={settings} className="card" />
                  </ColumnStyle>
                ))}
              </Slider>
            </SliderContainer>
          </div>
        </RowWrapper>
      </TrackVisibility>
    </Context.Provider>
  );
};

export default styled(ArticleSlider)`
  padding: ${({ theme }) => theme.p1} 0;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1} 0;
  }
  .card {
    margin: 0px !important;
    .content {
      padding: 0px !important;
    }
  }
  margin-bottom: 60px;
`;
