/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card, Image } from 'semantic-ui-react';
import cx from 'classnames';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import SocialCountLine from '../../SocialCountLine';
// import SocialReaction from '../../SocialReaction';

const ArticleCardStyle = styled(Card)`
  width: 250px !important;
  height: auto;
  overflow: hidden;
  margin-right: 10px;
  box-shadow: 0 3px 6px #d4d4d5 !important;
  border-radius: 8px 8px 8px 8px !important;
  & .content {
    padding: 5px 10px !important;
    position: relative;
    & .footer {
      height: 89px;
    }
  }
`;

const CardHeader = styled.div`
  text-transform: uppercase;
  font-size: ${props => props.theme.sizeMobileText};
`;

const VideoNameLine = compose(
  withProps(props => ({
    children: (
      <React.Fragment>
        <Image
          src={_.get(props, 'logo_url')}
          height="25px"
          width="auto"
          spaced="right"
        />
        {_.get(props, 'name', '')}
      </React.Fragment>
    ),
  })),
)(CardHeader);

export const ArticleCard = props => {
  return (
    <ArticleCardStyle className={cx(props.className)}>
      <Card.Content>
        <div className="cover">
          <Image
            className="thumbnail"
            src={_.get(props, 'thumbnail_url')}
            as="a"
            href={_.get(props, 'url')}
          />
          <div className="coverFooter">
            {/* <SocialReaction
              isLiked={props.isLiked}
              isBookmarked={props.isBookmarked}
              targetId={props.articleId}
              targetType="video_question"
            /> */}
          </div>
        </div>
        <div className="footer">
          {/* <SocialCountLine
            likeCount={props.likeCount}
            bookmarkCount={props.bookmarkCount}
          /> */}
          <VideoNameLine {...props.college} />
          <p className="info">
            {_.compact([props.name, props.major]).join( ' | ')}
          </p>
        </div>
      </Card.Content>
    </ArticleCardStyle>
  );
};

ArticleCard.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  major: PropTypes.string,
  college: PropTypes.shape({
    logo_url: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default styled(ArticleCard)`
  .info {
    font-size: ${props => props.theme.sizeSmallText};
    line-height: 25px;
  }
  .cover {
    position: relative;
    .thumbnail {
      height: ${props => _.get(props, 'settings.card.height', 444.44)}px;
      width: auto;
      > img {
        height: 100% !important;
      }
    }
    .coverFooter {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
  .footer {
    padding: 7px;
  }
`;
