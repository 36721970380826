import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, ...rest }) => (
  <div className={cx(className, 'bl-HDivier')} {...rest} />
))`
  margin: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: ${({ theme }) => `12px ${theme.ps1}`};
  }
  border-bottom: ${({ theme }) => theme.divider};
  width: 273px;
`;
