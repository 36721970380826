import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, text }) => (
  <div className={cx(className, 'bl-Subtitle')}>
    <div className="block">{text}</div>
  </div>
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1};
  }
  .block {
    font-weight: bold;
    font-size: ${({ theme }) => theme.sizeHeader};
    line-height: 28px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 20px;
      line-height: 18px;
    }
  }
`;
