import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { fromJS } from 'immutable';

import {
  Image,
  Responsive,
} from 'semantic-ui-react';
import Carousel from 'nuka-carousel';

import {
  MAX_MOBILE, MIN_MOBILE
} from '../constants';


const SliderImage = styled(Image)`
  height: 375px;
  margin-right: 20px;
  background-size: cover;
  object-fit: cover;
  object-position: top;

  @media (min-width: ${MAX_MOBILE}px) {
    height: 520px;
  }

   @media (min-width: 1000px) {
    height: 520px;
  }
`;

const SliderImages = styled(({
  images=[],
  className,
}) => {
  return (
    <React.Fragment>
      <Responsive as={React.Fragment} minWidth={MIN_MOBILE}>
        <Carousel
          slidesToShow={1.4}
          wrapAround
          className={className}
          cellAlign="center"
        >
          {
            images.map((item, index) => {
              return <SliderImage key={index} src={item.get('image')} alt={item.get('alt', '')} />
            })
          }
        </Carousel>
      </Responsive>
      <Responsive as={React.Fragment} maxWidth={MAX_MOBILE}>
        <Carousel
          slidesToShow={1.2}
          wrapAround
          className={className}
          cellAlign="center"
        >
          {
            images.map((item, index) => {
              return <SliderImage key={index} src={item.get('image')} alt={item.get('alt', '')} />
            })
          }
        </Carousel>
      </Responsive>
    </React.Fragment>
  );
})`
  & .slider-list {
    .slider-slide {
      padding: 0 20px !important;
      @media (max-width: ${MAX_MOBILE}px) {
        padding: 0 5px !important;
      }
    }
    margin-bottom: 30px;
  }
  & .slider-control-bottomcenter {
    bottom: -50px !important;
    ul {
      display: flex;
    }
  }
  & .slider-control-centerleft, .slider-control-centerright {
    display: none;
  }
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const SliderWidget = {
  // Internal id of the component
  id: "Image-Slider",
  // Visible label
  label: "Image-Slider",
  fields: [
    {
      name: 'images',
      label: 'Images',
      widget: 'list',
      fields: [
        { label: 'Image', name: 'image', widget: 'image' },
        { label: 'Alt', name: 'alt', widget: 'string', required: false }
      ]
    },
  ],
  pattern: /^{{{Slider images="(.*)"}}}$/,
  fromBlock: function(match) {
    const imagesStr = match[1];
    let images = [];
    try {
      images = JSON.parse(`[${imagesStr}]`);
    } catch (err) {
      // invalid input, ignore it
    }
    return {
      images: fromJS(images),
    };
  },
  toBlock: function(obj) {
    const imagesStr = _.trimEnd(_.trimStart(JSON.stringify(obj.images || []), '['), ']');
    return `{{{Slider images="${imagesStr}"}}}`;
  },
  toView: function(obj) {
    return (
      <SliderImages images={obj.images} />
    );
  },
  toPreview: function(obj) {
    return (
      <SliderImages images={obj.images} />
    );
  }  
}

export default SliderWidget;
