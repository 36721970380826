import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Button from '../components/Blocks/Button';


export const ButtonWidget = {
  // Internal id of the component
  id: "Custom-Button",
  // Visible label
  label: "Custom-Button",
  fields: [
    {
      name: 'text', label: 'Text', widget: 'string'
    },
    {
      name: 'href', label: 'Link', widget: 'string'
    },
    {
      name: 'size', label: 'Size', widget: 'select', options: ['', 'h1', 'h2', 'h3']
    },
    {
      name: 'color', label: 'Color', widget: 'select', options: ['', 'pink', 'orange', 'yellow', 'teal']
    },
    {
      name: 'align', label: 'Align', widget: 'select', options: ['', 'left', 'right', 'center']
    },
  ],
  pattern: /^{{{Button text="(.*)" href="(.*)" size="(.*)" color="(.*)" align="(.*)"}}}$/,
  fromBlock: function(match) {
    return {
      text: match[1],
      href: atob(match[2]),
      size: match[3],
      color: match[4],
      align: match[5],
    };
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return `{{{Button text="${obj.text}" href="${btoa(obj.href)}" size="${obj.size}" color="${obj.color}" align="${obj.align}"}}}`;
  },
  toView: function(obj) {
    const { size, color, align, ...rest } = obj;
    return (
      <Button
        {...rest}
        className={cx(_.values({
          size,color, align
        }))}
      >
        {obj.text}
      </Button>
    );
  },
  toPreview: function(obj) {
    const { size, color, align } = obj;
    return (
      <Button
        className={cx(_.values({
          size,color, align
        }))}
      >
        {obj.text}
      </Button>
    );
  }  
}

export default ButtonWidget;
