import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, caption, text }) => (
  <div className={cx(className, 'bl-Quote')}>
    <div className="block">
      <div className="quote">{text}</div>
      <div className="caption">{caption}</div>
    </div>
  </div>
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1};
  }
  .block {
    padding-left: ${({ theme }) => theme.p1};
    border-left: ${({ theme }) => theme.divider};
    .quote {
      color: ${({ theme }) => theme.altPink};
      font-size: 50px;
      line-height: 1.2em;
      padding: ${({ theme }) => theme.p1} 0px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 30px;
      }
    }
    .caption {
      color: ${({ theme }) => theme.primaryBlack};
      font-size: 14px;
      opacity: 0.7;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 11px;
      }
    }
  }
`;
