import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import cx from 'classnames';

export default styled(({ className, caption, image }) => (
  <div className={cx(className, 'bl-BodyImage')}>
    <div className="block">
      <Image src={image} className="image" />
      <div className="caption">{caption}</div>
    </div>
  </div>
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1};
  }
  .block {
    .caption {
      padding: 10px 0;
      color: ${({ theme }) => theme.primaryBlack};
      font-size: 14px;
      opacity: 0.7;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 11px;
      }
    }
    .image {
      width: 100%;
      max-width: 100%;
    }
  }
`;
