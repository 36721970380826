import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, text }) => (
  <div className={cx(className, 'bl-BodyText')}>
    <div className="block">{text}</div>
  </div>
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1};
  }
  .block {
    font-size: 18px;
    line-height: 1.5em;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 16px;
    }
    img {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
`;
