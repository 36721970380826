import styled from 'styled-components';
import _ from 'lodash';

export const SliderContainer = styled.div`
  position: relative;

  &:before {
    position: absolute;
    top: ${props => _.get(props, 'settings.card.height', 444.44)}px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      top: ${props => _.get(props, 'settings.resp.mobile.card.height', 444.44)}px;
    }

    content: '';
    bottom: 0px;
    background: ${props =>
      _.get(
        props,
        'settings.container.background',
        props.theme.altLightYellow,
      )};
    left: 0px;
    right: 0px;
  }
  & .slick-slider {
    margin-left: 24px;
    padding-bottom: 28px;
    > .slick-list {
      > .slick-track {
        display: flex;
        margin-left: 0px !important;
        align-items: flex-end;
      }
    }
    .slick-slide {
      display: flex !important;
      :not(:last-child) {
        margin-right: 15px;
      }
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots {
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;
      padding-top: 4px;
      line-height: 6px;
      position: relative;
      padding-bottom: 32px;
      li {
        line-height: 6px;
        position: relative;
        display: inline-block;
        height: 6px;
        width: 6px;
        margin: 0 3px;
        padding: 0;
        cursor: pointer;
        button {
          border: 0;
          color: #f2ca11;
          display: block;
          outline: none;
          line-height: 0px;
          font-size: 0px;
          padding: 3px;
          border-radius: 6px;
          opacity: 1;
          cursor: pointer;
        }
      }
      .slick-active {
        button {
          color: white;
          opacity: 1;
          margin-right: 12px;
          &:before {
            color: white;
          }
        }
      }
    }
  }
`;

export default SliderContainer;
