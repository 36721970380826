import styled from 'styled-components';

export default styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  .bl-PageImage {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      order: -1;
    }
  }
`;
